<!--
 * @Author: CL
 * @Date: 2021-07-22 10:56:20
 * @LastEditTime: 2021-07-27 17:17:39
 * @Description: 留言管理
-->

<template>
  <div class="messageback-contain cl-wrap">
    <div class="cl-table-main" v-loading="loading">
      <div class="main-top">
        <div>留言信息</div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center' }"
        class="table-wrap"
        max-height="580"
      >
        <el-table-column
          prop="nickName"
          label="昵称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="留言内容"
          align="center"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="250"
              trigger="hover"
              :content="scope.row.content">
                <div v-text="scope.row.content" slot="reference" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all"></div>
              </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagination 
          :page="page" 
          :size="size" 
          :total="total" 
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { queryMes, delMessage } from '@/api/message';
import Pagination from '@/components/Pagination';

export default {
  data(){
    return {
      page: 1,
      size: 10,
      total: 0,
      loading: true,
      tableData: []
    }
  },

  methods: {
    async queryMes(){
      this.loading = true;
      const { page, size } = this;
      try{
        const res = await queryMes(page, size);
        this.loading = false;
        if(res.code == 200){
          const { rows, count } = res.data;
          this.total = count;
          this.tableData = rows;
        }
      }catch(err){
        console.log(err);
      }
    },

    handleSizeChange(val){
      this.size = val;
      this.queryMes();
    },

    handleCurrentChange(val){
      this.page = val;
      this.queryMes();
    },

    /**
     * 删除留言
     */
    handleDelete(data){
      const { id } = data;
      this.$utils.confirm('您确定要删除此留言吗?').then(async () => {
        const res = await delMessage(id);
        if(res.code == 200){
          this.$showMessage({
            type: 'success',
            message: '删除成功!'
          });
          this.queryMes();
        }
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消删除'
        }); 
      })
    }
  },

  created(){
    this.queryMes();
  },

  components: {
    Pagination
  }
}
</script>

<style lang="less" scoped>

</style>
